@font-face {
  font-family: "Myriad Pro Medium";
  src: url("../fonts/Myriad_Pro/Myriad_Pro-regular.otf");
}

@font-face {
  font-family: "Display Regular";
  src: url("../fonts/Display/SFUIDisplay-Regular.otf");
}

@font-face {
  font-family: "Display Medium";
  src: url("../fonts/Display/SFUIDisplay-Medium.otf");
}

@font-face {
  font-family: "Display Bold";
  src: url("../fonts/Display/SFUIDisplay-Bold.ttf");
}

.btn,
.btn_radius {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  background: inherit;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-family: "Display Regular";
  font-size: 11px;
  cursor: pointer;
  padding: 10px 15px;
  text-decoration: none;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.btn {
  -webkit-border-radius: 5px;
  border-radius: 5px;
}
.btn.design {
  width: 135px;
  border: 1px solid #d0d0d0;
  text-align: center;
  text-transform: uppercase;
  font-size: 9px;
  line-height: 15px;
  color: #606060;
}
.btn.design:hover {
  border: 1px solid #ff8025;
  color: #ff8025;
}
.btn.btnMaim {
  background: #ff8025;
  color: #fff;
  text-transform: uppercase;
  font-size: 16px;
  -webkit-border-radius: 0;
  border-radius: 0;
  border: none;
  width: 270px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  padding: 0;
  height: 60px;
}
.btn.btnMaim i {
  font-size: 22px;
  width: 0;
  -webkit-transition: 1s;
  -o-transition: 1s;
  transition: 1s;
}
.btn.btnMaim:hover i {
  opacity: 1;
  padding-left: 25px;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  -webkit-transition: 1s;
  -o-transition: 1s;
  transition: 1s;
}
.btn.btnMaim i {
  opacity: 0;
  -webkit-transition: all 0.8s;
  -o-transition: all 0.8s;
  transition: all 0.8s;
}
.btn.btnMaim.btnMaimBIG {
  width: 330px;
  font-family: "Display Medium";
  font-size: 25px;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  background: #fff;
  color: #ff8025;
  -webkit-box-shadow: 0px 0px 100px 0px #ff8025;
  box-shadow: 0px 0px 100px 0px #ff8025;
  font-weight: 100;
}
.btn.btnMaim.btnMaimBIG:hover {
  background: #ff8025;
  color: #fff;
}
.btn.btnMaim.btnMaim_nona {
  width: 164px;
  height: 40px;
  border: 1px solid #ff8025;
  background: #fff;
  color: #ff8025;
}
.btn.btnMaim.btnMaim_nona:hover {
  background: #ff8025;
  color: #fff;
}
.btn.btnMaim.nonaMain {
  width: 220px;
  -webkit-box-shadow: -1px 7px 13px -2px #ff8025;
  box-shadow: -1px 7px 13px -2px #ff8025;
}
.btn.btnMaim.btnMaim_wide {
  width: 342px;
  -webkit-box-shadow: -1px 7px 13px -2px #ff8025;
  box-shadow: -1px 7px 13px -2px #ff8025;
}
.btn.btnMaim.btnMaim_wide i {
  font-size: 25px;
}

.btn_radius {
  -webkit-border-radius: 20px;
  border-radius: 20px;
}
.btn_radius.prev {
  color: #606060;
  font-size: 0;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  background: #fdfdfc;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  -webkit-box-shadow: 0px 0px 21px 0px #e9e9e9;
  box-shadow: 0px 0px 21px 0px #e9e9e9;
}
.btn_radius.prev:hover {
  font-size: 11px;
}
.btn_radius.prev:hover i {
  padding-right: 12px;
}
.btn_radius.prev i {
  font-size: 14px;
}

.switch_nona {
  text-decoration: none;
  position: absolute;
  margin-top: -10px;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
.switch_nona:hover:after {
  color: #fff;
  background: #ff8025;
  border: 1px solid #ff8025;
}
.switch_nona:after {
  -webkit-border-radius: 50%;
  border-radius: 50%;
  border: 1px solid #606060;
  font-family: "Font Awesome 5 pro";
  font-size: 18px;
  font-weight: 100;
  width: 20px;
  line-height: 1.2;
  height: 20px;
  display: block;
  text-align: center;
  color: #606060;
  margin: 0;
}
.switch_nona.prev:after {
  content: "\f104";
}
.switch_nona.next:after {
  content: "\f105";
}

.switch_BIG {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}
.switch_BIG span {
  position: relative;
  display: block;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
}
.switch_BIG span img {
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  width: 28px;
  height: 168px;
  display: block;
}
.switch_BIG span:after {
  font-family: "Font Awesome 5 pro";
  font-size: 36px;
  top: 50%;
  font-weight: 100;
  display: block;
  z-index: 2;
  position: absolute;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  text-align: center;
  color: #606060;
  margin: -10px 0 0;
  opacity: 0;
}
.switch_BIG.prev span:after {
  right: 18px;
  content: "\f104";
}
.switch_BIG.next span:after {
  left: 18px;
  content: "\f105";
}
.switch_BIG:hover span img {
  width: 48px;
  margin-top: -24px;
  height: 216px;
}
.switch_BIG:hover span:after {
  opacity: 1;
}

.btn.add_delete {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  text-transform: uppercase;
  height: 40px;
  -webkit-border-radius: 30px;
  border-radius: 30px;
  display: flex;
  font-size: 0;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  padding: 20px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.btn.add_delete:hover {
  padding: 0 40px 0 25px;
  font-size: 11px;
}
.btn.add_delete:hover:after {
  right: 12px;
  left: auto;
  margin: 0;
}
.btn.add_delete:after {
  font-family: "Font Awesome 5 pro";
  line-height: 2;
  top: 0;
  left: 50%;
  font-size: 20px;
  position: absolute;
  display: inline-block;
}
.btn.add_delete.btn_add {
  border: 1px solid #949393;
  color: #fff;
}
.btn.add_delete.btn_add:after {
  margin-left: -8px;
  content: "\f067";
  color: #949393;
}
.btn.add_delete.btn_add:hover {
  color: #949393;
}
.btn.add_delete.btn_delete {
  border: none;
  color: #606060;
  background: #606060;
}
.btn.add_delete.btn_delete:after {
  margin-left: -7px;
  content: "\f00d";
  color: #fff;
}
.btn.add_delete.btn_delete:hover {
  color: #fff;
}

* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  outline: none;
  letter-spacing: 0;
}

button {
  outline: 0;
}

body {
  background: #fff;
  padding: 0;
  margin: 0 auto;
  max-width: 1920px;
  width: 100%;
  overflow-x: hidden;
}

main {
  height: 100vh;
  width: 100%;
  background: #f1f1f1;
  overflow-y: scroll;
  /*margin-top: 70px;*/
}

.hidden {
  display: none !important;
}

.btn,
.btn_radius {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  background: inherit;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-family: "Display Regular";
  font-size: 11px;
  cursor: pointer;
  padding: 10px 15px;
  text-decoration: none;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.btn {
  -webkit-border-radius: 5px;
  border-radius: 5px;
}
.btn.design {
  width: 135px;
  border: 1px solid #d0d0d0;
  text-align: center;
  text-transform: uppercase;
  font-size: 9px;
  line-height: 15px;
  color: #606060;
}
.btn.design:hover,
.btn.design.active {
  border: 1px solid #ff8025;
  color: #ff8025;
}
.btn.btnMaim {
  background: #ff8025;
  color: #fff;
  text-transform: uppercase;
  font-size: 16px;
  -webkit-border-radius: 0;
  border-radius: 0;
  border: none;
  width: 270px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  padding: 0;
  height: 60px;
}
.btn.btnMaim i {
  font-size: 22px;
  width: 0;
  -webkit-transition: 1s;
  -o-transition: 1s;
  transition: 1s;
}
.btn.btnMaim:hover i {
  opacity: 1;
  padding-left: 25px;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  -webkit-transition: 1s;
  -o-transition: 1s;
  transition: 1s;
}
.btn.btnMaim i {
  opacity: 0;
  -webkit-transition: all 0.8s;
  -o-transition: all 0.8s;
  transition: all 0.8s;
}
.btn.btnMaim.btnMaimBIG {
  width: 330px;
  font-family: "Display Medium";
  font-size: 25px;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  background: #fff;
  color: #ff8025;
  -webkit-box-shadow: 0px 0px 100px 0px #ff8025;
  box-shadow: 0px 0px 100px 0px #ff8025;
  font-weight: 100;
}
.btn.btnMaim.btnMaimBIG:hover {
  background: #ff8025;
  color: #fff;
}
.btn.btnMaim.btnMaim_nona {
  width: 164px;
  height: 40px;
  border: 1px solid #ff8025;
  background: #fff;
  color: #ff8025;
}
.btn.btnMaim.btnMaim_nona:hover {
  background: #ff8025;
  color: #fff;
}
.btn.btnMaim.nonaMain {
  width: 220px;
  -webkit-box-shadow: -1px 7px 13px -2px #ff8025;
  box-shadow: -1px 7px 13px -2px #ff8025;
}
.btn.btnMaim.btnMaim_wide {
  width: 342px;
  -webkit-box-shadow: -1px 7px 13px -2px #ff8025;
  box-shadow: -1px 7px 13px -2px #ff8025;
}
.btn.btnMaim.btnMaim_wide i {
  font-size: 25px;
}

.btn_radius {
  -webkit-border-radius: 20px;
  border-radius: 20px;
}
.btn_radius.prev {
  color: #606060;
  font-size: 0;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  background: #fdfdfc;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  -webkit-box-shadow: 0px 0px 21px 0px #e9e9e9;
  box-shadow: 0px 0px 21px 0px #e9e9e9;
}
.btn_radius.prev:hover {
  font-size: 11px;
  width: auto !important;
}

.btn_radius.prev:hover i {
  padding-right: 12px;
}
.btn_radius.prev i {
  font-size: 14px;
}

.switch_nona {
  text-decoration: none;
  position: absolute;
  margin-top: -10px;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
.switch_nona:hover:after {
  color: #fff;
  background: #ff8025;
  border: 1px solid #ff8025;
}
.switch_nona:after {
  -webkit-border-radius: 50%;
  border-radius: 50%;
  border: 1px solid #606060;
  font-family: "Font Awesome 5 pro";
  font-size: 18px;
  font-weight: 100;
  width: 20px;
  line-height: 1.2;
  height: 20px;
  display: block;
  text-align: center;
  color: #606060;
  margin: 0;
}
.switch_nona.prev:after {
  content: "\f104";
}
.switch_nona.next:after {
  content: "\f105";
}

.switch_BIG {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}
.switch_BIG span {
  position: relative;
  display: block;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
}
.switch_BIG span img {
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  width: 28px;
  height: 168px;
  display: block;
}
.switch_BIG span:after {
  font-family: "Font Awesome 5 pro";
  font-size: 36px;
  top: 50%;
  font-weight: 100;
  display: block;
  z-index: 2;
  position: absolute;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  text-align: center;
  color: #606060;
  margin: -10px 0 0;
  opacity: 0;
}
.switch_BIG.prev span:after {
  right: 18px;
  content: "\f104";
}
.switch_BIG.next span:after {
  left: 18px;
  content: "\f105";
}
.switch_BIG:hover span img {
  width: 48px;
  margin-top: -24px;
  height: 216px;
}
.switch_BIG:hover span:after {
  opacity: 1;
}

.btn.add_delete {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  text-transform: uppercase;
  height: 40px;
  -webkit-border-radius: 30px;
  border-radius: 30px;
  display: flex;
  font-size: 0;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  padding: 20px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.btn.add_delete:hover {
  padding: 0 40px 0 25px;
  font-size: 11px;
}
.btn.add_delete:hover:after {
  right: 12px;
  left: auto;
  margin: 0;
}
.btn.add_delete:after {
  font-family: "Font Awesome 5 pro";
  line-height: 2;
  top: 0;
  left: 50%;
  font-size: 20px;
  position: absolute;
  display: inline-block;
}
.btn.add_delete.btn_add {
  border: 1px solid #949393;
  color: #fff;
}
.btn.add_delete.btn_add:after {
  margin-left: -8px;
  content: "\f067";
  color: #949393;
}
.btn.add_delete.btn_add:hover {
  color: #949393;
}
.btn.add_delete.btn_delete {
  border: none;
  color: #606060;
  background: #606060;
}
.btn.add_delete.btn_delete:after {
  margin-left: -7px;
  content: "\f00d";
  color: #fff;
}
.btn.add_delete.btn_delete:hover {
  color: #fff;
}

.canvasComponents {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 0;
  margin: 0;
  -webkit-box-shadow: 0px 0px 21px 0px #e9e9e9;
  box-shadow: 0px 0px 21px 0px #e9e9e9;
  background: #fff;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
}
.canvasComponents .components {
  border-right: 1px solid #e3e3e3;
  padding: 0;
  list-style: none;
  font-family: "Myriad Pro Medium";
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 0;
  color: #606060;
  position: relative;
  font-size: 16px;
}
.canvasComponents .components:first-of-type {
  font-size: 26px;
}
.canvasComponents .components:last-of-type {
  border-right: none;
}
.canvasComponents .components:nth-child(2) a {
  padding-right: 25px;
}
.canvasComponents .components:nth-child(3) {
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}
.canvasComponents .components:nth-child(3) .arrows a {
  font-size: 12px;
}
.canvasComponents .components:nth-child(3) .arrows a:first-of-type {
  margin-top: 2px;
}
.canvasComponents .components:nth-child(3) .arrows a:last-of-type {
  margin-top: -5px;
}
.canvasComponents .components:nth-child(4) a {
  font-style: italic;
}
.canvasComponents .components:nth-child(4) a span {
  border-bottom: 3px solid #000;
}
.canvasComponents .components:nth-child(5) a span {
  border-bottom: 3px solid #a1c65b;
}
.canvasComponents .components:nth-child(5) a i {
  margin-left: 20px;
}
.canvasComponents .components:hover .in_list {
  display: block;
}
.canvasComponents .components:hover .color,
.canvasComponents .components:hover .style {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
.canvasComponents .components a {
  text-decoration: none;
  padding: 5px 15px; /*корректировка*/
  cursor: pointer; /*новый стиль*/
  color: inherit;
  height: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
.canvasComponents .components a:hover {
  color: #000;
}
.canvasComponents .components a .icon {
  fill: #606060;
  stroke: #606060;
}
.canvasComponents .components a:hover .icon {
  fill: #000;
  stroke: #000;
}
.canvasComponents .components a img {
  width: 26px;
  display: block;
}
.canvasComponents .components a .icon_dark {
  display: none;
}
.canvasComponents .components .in_list {
  position: absolute;
  top: 100%;
  z-index: 10;
  border-top: 1px solid #e3e3e3;
  left: 0;
  background: #fff;
  display: none;
  padding: 0;
  margin: 0;
  list-style: none;
}
.canvasComponents .components .in_list li:first-of-type {
  font-size: inherit;
}
.canvasComponents .components .in_list li:nth-child(4) {
  font-style: initial;
}
.canvasComponents .components .in_list.font {
  width: 186px;
  opacity: 0.9;
  overflow: auto;
  max-height: 420px;
}
.canvasComponents .components .in_list.font .blur {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: transparent;
  left: 0;
  top: 0;
  -webkit-filter: blur(5px);
  -moz-filter: blur(5px);
  -o-filter: blur(5px);
  -ms-filter: blur(5px);
  filter: blur(5px);
}
.canvasComponents .components .in_list.font li {
  -webkit-box-pack: end;
  -webkit-justify-content: end;
  -ms-flex-pack: end;
  justify-content: end;
}
.canvasComponents .components .in_list.font li a {
  opacity: 1;
  padding: 5px 10px;
}
.canvasComponents .components .in_list.style {
  -webkit-box-shadow: 0px 0px 21px 0px #e9e9e9;
  box-shadow: 0px 0px 21px 0px #e9e9e9;
}
.canvasComponents .components .in_list.style li:first-of-type a {
  font-style: initial;
  padding-left: 20px;
}
.canvasComponents .components .in_list.style li:nth-child(3) a {
  font-style: initial;
  text-decoration: underline;
}
.canvasComponents .components .in_list.style li:last-of-type {
  text-decoration: line-through;
}
.canvasComponents .components .in_list.style li:last-of-type a {
  padding-right: 20px;
  font-style: initial;
}
.canvasComponents .components .in_list.style li a {
  padding: 5px 10px;
}
.canvasComponents .components .in_list.size li a {
  padding: 5px 20px;
}
.canvasComponents .components .in_list.color {
  width: 186px;
  padding: 12px;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.canvasComponents .components .in_list.color li {
  -webkit-box-pack: end;
  -webkit-justify-content: end;
  -ms-flex-pack: end;
  justify-content: end;
  border: none;
}
.canvasComponents .components .in_list.color li:nth-child(1) a span {
  background: #fff;
}
.canvasComponents .components .in_list.color li:nth-child(2) a span {
  background: #f3e95b;
}
.canvasComponents .components .in_list.color li:nth-child(3) a span {
  background: #ecc634;
}
.canvasComponents .components .in_list.color li:nth-child(4) a span {
  background: #ce4a2c;
}
.canvasComponents .components .in_list.color li:nth-child(5) a span {
  background: #e4e4e3;
}
.canvasComponents .components .in_list.color li:nth-child(6) a span {
  background: #ceda57;
}
.canvasComponents .components .in_list.color li:nth-child(7) a span {
  background: #a1c65b;
}
.canvasComponents .components .in_list.color li:nth-child(8) a span {
  background: #b91e62;
}
.canvasComponents .components .in_list.color li:nth-child(9) a span {
  background: #7d7d7e;
}
.canvasComponents .components .in_list.color li:nth-child(10) a span {
  background: #74bcc6;
}
.canvasComponents .components .in_list.color li:nth-child(11) a span {
  background: #5ea588;
}
.canvasComponents .components .in_list.color li:nth-child(12) a span {
  background: #77398f;
}
.canvasComponents .components .in_list.color li:nth-child(13) a span {
  background: #1e1e1e;
}
.canvasComponents .components .in_list.color li:nth-child(14) a span {
  background: #6694ce;
}
.canvasComponents .components .in_list.color li:nth-child(15) a span {
  background: #4b519f;
}
.canvasComponents .components .in_list.color li:nth-child(16) a span {
  background: #5c4396;
}
.canvasComponents .components .in_list.color li a {
  padding: 0;
  width: 30px;
  margin: 5px;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  height: 30px;
  border: 1px solid #e3e3e3;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  display: block;
}
.canvasComponents .components .in_list.color li a:hover {
  width: 40px;
  border: 1px solid #000;
  height: 40px;
  padding: 5px;
  margin: 0;
}
.canvasComponents .components .in_list.color li a span {
  display: block;
  width: 100%;
  height: 100%;
  border: none;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}
.canvasComponents ::-webkit-scrollbar-button {
  /*background-image: url("");*/
  background-repeat: no-repeat;
  width: 5px;
  height: 0px;
}
.canvasComponents ::-webkit-scrollbar-track {
  background-color: #eaeaea;
  margin-right: 5px;
}
.canvasComponents ::-webkit-scrollbar-thumb {
  -webkit-border-radius: 0px;
  border-radius: 0px;
  background-color: #cccccb;
}
.canvasComponents ::-webkit-scrollbar-thumb:hover {
  background-color: #cccccb;
}
.canvasComponents ::-webkit-resizer {
  /*background-image: url("");*/
  background-repeat: no-repeat;
  width: 3px;
  height: 0px;
}
.canvasComponents ::-webkit-scrollbar {
  width: 3px;
}

.myself {
  width: 335px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.myself .stages {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.myself .stages .stage {
  width: 110px;
  height: 5px;
  background: #c9c9c7;
}
.myself .stages .stage:first-of-type {
  -webkit-border-radius: 20px 0 0 20px;
  border-radius: 20px 0 0 20px;
}
.myself .stages .stage:last-of-type {
  -webkit-border-radius: 0 20px 20px 0;
  border-radius: 0 20px 20px 0;
}
.myself .text {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-weight: 100;
  width: 100%;
  color: #606060;
  padding-top: 15px;
  font-family: "Display Regular";
  text-transform: uppercase;
  font-size: 18.9px;
}
.myself .text .counter {
  padding-right: 20px;
}

.menuMain {
  height: 100%;
  min-height: 100vh;
  width: 300px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  background: #fff;
  border-left: 1px solid #e3e3e3;
}
.menuMain .top_part .header {
  background: #ff8025;
  width: 100%;
  padding: 24px 15px 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  color: #fff;
}
.menuMain .top_part .header a {
  cursor: pointer;
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  text-transform: uppercase;
  margin: 0;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  color: inherit;
  font-family: "Display Medium";
  font-size: 14px;
  font-weight: 100;
  text-decoration: none;
}
.menuMain .top_part .header a:hover i {
  background: #fff;
  color: #ff8025;
}
.menuMain .top_part .header a i {
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 16px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  width: 24px;
  height: 22px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  border: 1px solid #fff;
}
.menuMain .top_part .header a.btn_radius i {
  border: none;
  background: transparent;
}
.menuMain .top_part .header a.btn_radius .table_nona {
  width: 269px;
  left: -1px;
}
.menuMain .top_part .header p {
  font-family: "Display Medium";
  font-weight: 100;
  padding-top: 10px;
  font-size: 10px;
  width: 100%;
  text-transform: uppercase;
}
.menuMain .top_part .header .dropout {
  border: 1px solid #fff;
  width: 150px;
  color: #fff;
  font-family: "Display Medium";
  margin-bottom: 20px;
  font-weight: 100;
  font-size: 10px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.menuMain .top_part .header .dropout:hover {
  background: #fff;
  color: #ff8025;
}
.menuMain .top_part .header .btns_bottom {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 100%;
}
.menuMain .top_part .header .btns_bottom .btn_bottom {
  width: 130px;
  border: 1px solid #fff;
  -webkit-border-radius: 5px 5px 0 0;
  border-radius: 5px 5px 0 0;
  border-bottom: 0;
  color: #fff;
  font-size: 14px;
  padding: 10px 0;
}
.menuMain .top_part .header .btns_bottom .btn_bottom.active {
  background: #fff;
  color: #ff8025;
}
.menuMain .top_part .header .btns_bottom .btn_bottom:hover {
  background: #fff;
  color: #ff8025;
}
.menuMain .top_part .header .btns_bottom .btn_bottom i {
  padding-left: 18px;
}
.menuMain .top_part .list_points {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  padding: 0;
  list-style: none;
  margin: 0;
}
.menuMain .top_part .list_points li {
  margin: 0;
  padding: 0;
  width: 100%;
}
.menuMain .top_part .list_points li a {
  padding: 15px 25px 15px 30px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  font-family: "Display Regular";
  text-transform: uppercase;
  font-size: 11px;
  color: #606060;
  text-decoration: none;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  position: relative;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 1px solid #e3e3e3;
  width: 100%;
}
.menuMain .top_part .list_points li a:after {
  display: block;
  content: "";
  width: 5px;
  height: 5px;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  right: 25px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  background: #ceccca;
  position: absolute;
}
.menuMain .top_part .list_points li:hover a {
  background: #faeee1;
  color: #ff8025;
}
.menuMain .top_part .list_points li:hover a:after {
  background: #ff8025;
}
.menuMain .top_part .list_points li a svg {
  display: block;
  width: 40px;
  height: 40px;
  margin-right: 20px;
}
.menuMain .top_part .list_points li a .icon {
  fill: #cedad6;
  stroke: #cedad6;
}
.menuMain .top_part .list_points li:hover a .icon {
  fill: #ff8025;
  stroke: #ff8025;
}
.menuMain .top_part .list_points li .dropleft-content-construct-menu {
  display: none;
}
.menuMain .top_part .list_points li:hover .dropleft-content-construct-menu {
  display: block;
}
.menuMain .btn.footer {
  padding: 20px 15px;
  text-transform: inherit;
  width: 100%;
  -webkit-border-radius: 0;
  border-radius: 0;
}

.auto_bottom {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  background: #fff;
}
.auto_bottom .logo_bottom {
  max-width: 253px;
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0 40px 0;
  border-right: 1px solid #e3e3e3;
  border-top: 1px solid #e3e3e3;
}
.auto_bottom .logo_bottom img {
  display: block;
  width: 100%;
}
.auto_bottom .scrolling_items {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 0 20px;
  position: relative;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: flex-start;
  width: 100%;
  border-right-color: #eeeeee;
  border-right-style: solid;
  border-right-width: 1px;
  border-top-color: #eeeeee;
  border-top-width: 1px;
  border-top-style: solid;
}
.auto_bottom .scrolling_items .switch_nona {
  top: 50%;
}
.auto_bottom .scrolling_items .switch_nona.prev {
  left: 50px;
}
.auto_bottom .scrolling_items .switch_nona.next {
  right: 50px;
}
.auto_bottom .scrolling_items .item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 130px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  font-family: "Display Bold";
  font-size: 10px;
  margin-left: 17px;
  padding-top: 10px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100px;
}
.auto_bottom .scrolling_items .item img {
  width: 100%;
}

.nona_cars {
  max-width: 515px;
  width: 100%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  background: #fff;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.nona_cars .nona_car {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin: 13px 9px;
  width: 85px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  padding: 17px 15px;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  border: 1px solid #fff;
}
.nona_cars .nona_car:hover,
.nona_cars .nona_car.active {
  border: 1px solid #ff8025;
}
.nona_cars .nona_car:hover .plain,
.nona_cars .nona_car.active .plain {
  display: none;
}
.nona_cars .nona_car:hover .active,
.nona_cars .nona_car.active .active {
  display: block;
}
.nona_cars .nona_car img {
  width: 100%;
}
.nona_cars .nona_car .active {
  display: none;
}

.text_square {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
}
.text_square p {
  font-family: "Display Regular";
  font-size: 10px;
  text-transform: uppercase;
  margin: 0;
  color: #000;
}
.text_square.text_squareS {
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
}
.text_square.text_squareS p {
  padding-bottom: 23px;
}
.text_square.text_squareS .square {
  max-width: 230px;
  margin-left: 10px;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-shadow: 0px 0px 21px 0px #e9e9e9;
  box-shadow: 0px 0px 21px 0px #e9e9e9;
  padding: 5px 0;
}
.text_square.text_squareS .square .square_color {
  margin: 4px 7px;
}
.text_square.text_squareS .square .square_color__add {
  background: #ececec;
  border: 1px solid #cccccb;
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.text_square.text_squareS .square .square_color__add:after {
  font-family: "Font Awesome 5 pro";
  font-size: 20px;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  position: absolute;
  display: inline-block;
  margin: 0;
  content: "\f067";
  color: #949393;
}
.text_square.text_squareS .square .square_color__add:hover:after {
  color: #fff;
}

.square {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  background: #fff;
  margin-left: 20px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.square_color {
  width: 40px;
  height: 40px;
  display: block;
  margin: 0;
  padding: 0;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  background: #ffffff;
  border: 1px solid #cecdcd;
}

.table_nona table thead tr {
  font-family: "Display Regular";
  font-size: 8.97px;
  color: #a9a9a9;
  font-weight: 100;
}
.table_nona table thead tr th {
  text-align: left;
}
.table_nona table thead tr th:last-of-type {
  text-align: center;
}

.table_nona table tbody {
  border: 1px solid #eaeaea;
  -webkit-border-radius: 3px;
  border-radius: 3px;
}
.table_nona table tbody tr {
  font-family: "Display Regular";
  background: #f5f5f6;
  font-size: 10.97px;
  color: #606060;
  border: #000;
}
.table_nona table tbody tr:first-of-type td .square_color {
  background: #fff;
  border: 1px solid #e3e3e3;
}
.table_nona table tbody tr:last-of-type td .square_color {
  background: #000;
}
.table_nona table tbody tr td .square_color {
  width: 20px;
  height: 20px;
}
.table_nona table tbody tr td:nth-child(2) {
  padding: 10px;
  width: 25%;
}

.design_choice {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 70%;
  min-width: 980px;
  margin: 0 auto;
}
.design_choice .choice {
  height: initial;
  padding-top: 25px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: calc(100% - 300px);
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  position: relative;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  background: #ffffff;
}
.design_choice .choice .top {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-bottom: 0;
  justify-content: center;
}
.design_choice .choice .top .btn_radius {
  margin-left: 70px;
  padding: 0 18px;
  height: 43px;
  width: 43px;
  position: absolute;
  left: 0;
}
.design_choice .choice .top .btn_radius i {
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
.design_choice .choice .top .myself .stages .stage:nth-child(1) {
  background: #dfa834;
}
.design_choice .choice .top .myself .stages .stage:nth-child(2) {
  background: #d26d2c;
}
.design_choice .choice img {
  max-width: 1140px;
  width: inherit;
}
.design_choice .choice .canvasComponents {
  position: absolute;
  top: 180px;
  right: 197px;
  z-index: 10;
  box-shadow: 0 0 5px 2px rgba(221, 221, 221, 1);
}
@media screen and (max-height: 900px) {
  .design_choice .choice .canvasComponents {
    top: 160px;
  }
}
@media screen and (max-height: 835px) {
  .design_choice .choice .canvasComponents {
    top: 130px;
  }
}
.checkout {
  width: 70%;
  min-width: 980px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 30px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  margin: 0 auto;
}
.checkout .top {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  width: 100%;
  padding-bottom: 44px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.checkout .top .btn_radius {
  position: absolute;
  background: #ff8025;
  color: #fff;
  left: 0;
}
.checkout .top .btn_radius i {
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
.checkout .top .btn_radius:hover i {
  padding-right: 20px;
}
.checkout .top .myself .stages .stage:nth-child(1) {
  background: #dfa834;
}
.checkout .top .myself .stages .stage:nth-child(2) {
  background: #dfa834;
}
.checkout .top .myself .stages .stage:nth-child(3) {
  background: #d26d2c;
}
.checkout .forms {
  width: 100%;
  -webkit-box-shadow: 0px 0px 32px 21px #f2f2f3;
  box-shadow: 0px 0px 32px 21px #f2f2f3;
}
.checkout .forms h2 {
  font-family: "Display Medium";
  font-size: 16px;
  width: 100%;
  text-align: center;
  color: #fff;
  margin: 0;
  background: #ff8025;
  padding: 15px 0;
  font-weight: 100;
  -webkit-border-radius: 5px 5px 0 0;
  border-radius: 5px 5px 0 0;
}
.checkout .forms .form {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  background: #fff;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-border-radius: 0 0 5px 5px;
  border-radius: 0 0 5px 5px;
  width: 100%;
}
.checkout .forms .form .text {
  width: 100%;
  text-transform: uppercase;
  text-align: center;
  padding: 15px 0;
  font-family: "Display Medium";
  font-size: 12px;
  color: #606060;
}
.checkout .forms .form .fill_in {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding-bottom: 40px;
  justify-content: space-between;
}
.checkout .forms .form .fill_in .left {
  width: 55%;
}
.checkout .forms .form .fill_in .left .data {
  margin: 0;
  padding: 0 0 0 70px;
  width: 100%;
}
.checkout .forms .form .fill_in .left .data li {
  font-family: "Display Regular";
  font-size: 10px;
  margin: 20px 0;
  padding: 0;
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  list-style: none;
}
.checkout .forms .form .fill_in .left .data li:first-of-type {
  margin-top: 0;
}
.checkout .forms .form .fill_in .left .data li:last-of-type {
  margin-bottom: 0;
}
.checkout .forms .form .fill_in .left .data li span {
  width: 80px;
  line-height: 1.3;
}
.checkout .forms .form .fill_in .left .data li .number {
  position: relative;
  flex: 1;
}
.checkout .forms .form .fill_in .left .data li .number input {
  padding: 0 20px 0 40px;
  width: 100%;
}
.checkout .forms .form .fill_in .left .data li .number label {
  position: absolute;
  font-size: 13px;
  left: 15px;
  line-height: 39px;
  font-family: "Display Regular";
}
.checkout .forms .form .fill_in .left .data li input {
  width: 305px;
  color: #606060;
  height: 40px;
  padding: 0 15px;
  font-weight: 100;
  text-transform: uppercase;
  font-family: "Display Regular";
  font-size: 13px;
  border: 1px solid #cccccb;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  line-height: 30px;
}
.checkout .forms .form .fill_in .left .data li input.error {
  border-color: red;
  color: red;
}
.checkout .forms .form .fill_in .left .data li input,
.checkout .forms .form .fill_in .left .data li .btn-smal {
  display: inline-block;
  flex: 1;
  vertical-align: middle;
}
.checkout
  .forms
  .form
  .fill_in
  .left
  .data
  li
  input
  ::-webkit-input-placeholder {
  color: #ff8025;
  opacity: 100%;
}
.checkout .forms .form .fill_in .left .data li input ::-moz-placeholder {
  color: #ff8025;
  opacity: 100%;
}
.checkout .forms .form .fill_in .left .data li input ::-ms-input-placeholder {
  color: #ff8025;
  opacity: 100%;
}
.checkout .forms .form .fill_in .left .data li input ::placeholder {
  color: #ff8025;
  opacity: 100%;
}
.checkout .forms .form .fill_in .right {
  width: 40%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-right: 70px;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
}
.checkout .forms .form .fill_in .right > div {
  width: 100% !important;
}
.checkout .forms .form .fill_in .right .upload {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
.checkout .forms .form .fill_in .right .upload .btn.btnMaim_nona {
  background: #000;
  color: #fff;
  font-size: 12px;
  width: 215px;
}
.checkout .forms .form .fill_in .right .upload p {
  width: 179px;
  font-family: "Display Regular";
  font-size: 11px;
  line-height: 16px;
  color: #606060;
  margin: 0;
  padding-left: 24px;
}
.checkout .forms .form .fill_in .right .request {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}
.checkout .forms .form .fill_in .right .request .textarea {
  font-family: "Display Regular";
  font-size: 10px;
  text-transform: uppercase;
  color: #000;
}
.checkout .forms .form .fill_in .right .request textarea {
  resize: none;
  font-weight: 100;
  font-family: "Display Regular";
  font-size: 13px;
  color: #606060;
  height: 80px;
  font-family: "Display Regular";
  font-size: 13px;
  padding: 15px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  border: 1px solid #cccccb;
}
.checkout .forms .form .fill_in .right .request ::-webkit-scrollbar-button {
  /*background-image: url("");*/
  background-repeat: no-repeat;
  width: 3px;
  height: 0px;
}
.checkout .forms .form .fill_in .right .request ::-webkit-scrollbar-track {
  background-color: #eaeaea;
  margin-right: 5px;
}
.checkout .forms .form .fill_in .right .request ::-webkit-scrollbar-thumb {
  -webkit-border-radius: 0px;
  border-radius: 0px;
  background-color: #cccccb;
}
.checkout
  .forms
  .form
  .fill_in
  .right
  .request
  ::-webkit-scrollbar-thumb:hover {
  background-color: #cccccb;
}
.checkout .forms .form .fill_in .right .request ::-webkit-resizer {
  /*background-image: url("");*/
  background-repeat: no-repeat;
  width: 3px;
  height: 0px;
}
.checkout .forms .form .fill_in .right .request ::-webkit-scrollbar {
  width: 3px;
}
.checkout .forms .form .fill_in .right .request span {
  font-family: "Display Regular";
  font-size: 14px;
  color: #ff8025;
  padding-top: 10px;
}
.checkout .btn.nonaMain {
  margin: 45px auto 0;
}

.checkout_card {
  width: 70%;
  min-width: 980px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 30px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  margin: 0 auto;
}
.checkout_card .top {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  width: 100%;
  padding-bottom: 15px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.checkout_card .top .btn_radius {
  position: absolute;
  background: #ff8025;
  color: #fff;
  left: 0;
}
.checkout_card .top .btn_radius i {
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
.checkout_card .top .btn_radius:hover i {
  padding-right: 20px;
}
.checkout_card .top .myself .stages .stage:nth-child(1) {
  background: #dfa834;
}
.checkout_card .top .myself .stages .stage:nth-child(2) {
  background: #dfa834;
}
.checkout_card .top .myself .stages .stage:nth-child(3) {
  background: #d26d2c;
}
.checkout_card .cards {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding-top: 25px;
  width: 100%;
}
.checkout_card .cards .card {
  width: 33%;
  height: 360px;
  background: #fff;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  border: 1px solid #ebebec;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  -webkit-box-shadow: 3px 33px 32px -6px #ebebec;
  box-shadow: 3px 33px 32px -6px #ebebec;
}
.checkout_card .cards .card h2 {
  font-family: "Display Medium";
  font-size: 16px;
  font-weight: 500;
  color: #fff;
  background: #ff8025;
  width: 100%;
  text-align: center;
  padding: 12px 0;
  margin: 0;
  -webkit-border-radius: 5px 5px 0 0;
  border-radius: 5px 5px 0 0;
}
.checkout_card .cards .card .text_top {
  font-size: 14px;
  color: #ff8025;
  font-family: "Display Medium";
  width: 100%;
  text-align: center;
  padding: 13px 13px 0;
  text-transform: uppercase;
  height: 48px;
}
.checkout_card #views {
  height: 179px;
  padding: 0 30px;
}
.checkout_card #views img {
  height: 100%;
  object-fit: contain;
  width: 100%;
}
.checkout_card .cards .card .img-2 {
  width: 80%;
  margin: 17px auto 0;
  display: block;
}
.checkout_card .cards .card .special {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 280px;
  margin: 0 auto;
  padding-bottom: 18px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.checkout_card .cards .card .table_nona {
  position: relative;
  margin-top: 10px;
  padding: 0 10px;
}
.checkout_card .cards .card .table_nona.table_card-3 {
  border-bottom: 1px solid #eaeaea;
  padding-bottom: 10px;
}
.checkout_card .cards .card .table_nona.table_card-3 table {
  border-spacing: inherit;
}
.checkout_card .cards .card .table_nona.table_card-3 table thead tr th {
  color: #acabaa;
}
.checkout_card .cards .card .table_nona.table_card-3 table tbody tr {
  /*background: inherit;*/
}
.checkout_card .cards .card .table_nona.table_card-3 table tbody tr td {
  border: 1px solid #d4d4d2;
  color: #acabaa;
}
.checkout_card
  .cards
  .card
  .table_nona.table_card-3
  table
  tbody
  tr
  td:first-of-type {
  border-right: 0;
  text-transform: uppercase;
}
.checkout_card
  .cards
  .card
  .table_nona.table_card-3
  table
  tbody
  tr
  td:last-of-type {
  border-left: 0;
}
.checkout_card .cards .card .table_nona span {
  font-family: "Display Regular";
  font-size: 9px;
  display: block;
  padding: 10px 3px 0;
  color: #acabaa;
}
.checkout_card .cards .card .table_nona table {
  width: calc(100% - 20px);
}
.checkout_card .cards .card .table_nona table thead tr th {
  padding-bottom: 10px;
}
.table_nona table tbody tr td {
  color: #000;
}
.table_nona table tbody tr td:nth-child(1) {
  -webkit-border-radius: 5px 0 0 5px;
  border-radius: 5px 0 0 5px;
  padding-left: 10px;
  width: 50%;
}
.table_nona table tbody tr td:nth-child(3),
.table_nona table tbody tr td:nth-child(4) {
  text-align: center;
}
.table_nona table tbody tr td:last-of-type {
  -webkit-border-radius: 0 5px 5px 0;
  border-radius: 0 5px 5px 0;
}
.table_nona table tbody tr td .square_color {
  background: #fff;
  margin: 0 auto;
}
.table_nona table tbody tr td i {
  padding: 0 10px;
}
.checkout_card .cards .card .items {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  width: 100%;
  height: 179px;
}
.checkout_card .cards .card .items .switch_BIG {
  position: absolute;
  top: 20px;
}
.checkout_card .cards .card .items .switch_BIG.prev {
  left: 0;
}
.checkout_card .cards .card .items .switch_BIG.prev span:after {
  right: 13px;
}
.checkout_card .cards .card .items .switch_BIG.next {
  right: 0;
}
.checkout_card .cards .card .items .switch_BIG.next span:after {
  left: 13px;
}
.checkout_card .cards .card .items .switch_BIG span img {
  width: 30px;
  height: 120px;
}
.checkout_card .cards .card .items .switch_BIG:hover span img {
  width: 35px;
  margin-top: -10px;
  height: 135px;
}
.checkout_card .cards .card .items .item {
  width: 100%;
  padding: 0 30px;
}
.checkout_card .cards .card .items .item .img {
  height: 100%;
  display: flex;
  justify-content: center;
}
.checkout_card .cards .card .items .item img {
  display: block;
  height: 100%;
}
.checkout_card .cards .card .inputCheck {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  text-align: center;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding: 8px 13px 5px 32px;
}
.checkout_card .cards .card .inputCheck label {
  width: 390px;
  font-size: 14px;
  line-height: 0;
  font-family: "Display Regular";
  color: #ff8025;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  letter-spacing: 0;
  text-align: left;
  padding: 0 15px;
}
.checkout_card .cards .card .inputCheck label:before {
  display: inline-block;
  vertical-align: top;
  height: 18px;
  width: 18px;
  border: 1px solid #c6c6c6;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  position: absolute;
  top: -9px;
  left: -19px;
  content: "";
}
.checkout_card .cards .card .inputCheck label:after {
  display: inline-block;
  vertical-align: top;
  height: 5px;
  width: 9px;
  border-left: 2px solid transparent;
  border-bottom: 2px solid transparent;
  position: absolute;
  top: -4px;
  left: -14px;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
  content: "";
}
.checkout_card .cards .card .inputCheck input[type="checkbox"] {
  width: 0;
  height: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
}
.checkout_card .cards .card .inputCheck input[type="checkbox"].error + label {
  color: red;
}
.checkout_card
  .cards
  .card
  .inputCheck
  input[type="checkbox"]:checked
  + label:after {
  border-color: #ff8025;
  border-color: #ff8025;
}
.checkout_card
  .cards
  .card
  .inputCheck
  input[type="checkbox"]:checked
  + label:before {
  border-color: #ff8025;
}
.checkout_card .cards .card .inputCheck:before {
  display: inline-block;
  vertical-align: middle;
  height: 100%;
  font-size: 0px;
  content: "";
}
.checkout_card .cards .card .waves {
  margin-top: -20px;
}
.checkout_card .cards .card .quantity {
  height: 40px;
  -webkit-border-radius: 22px;
  border-radius: 22px;
  background: #ff8025;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  padding: 0 20px;
  font-family: "Display Medium";
  font-size: 16px;
  margin: 0 0 18px 10px;
  text-transform: uppercase;
  color: #fff;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.checkout_card .cards .card .quantity span {
  padding-left: 10px;
}
.checkout_card .cards .card .properties {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: 0 13px;
}
.checkout_card .cards .card .properties .propertie {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 6px 0;
}
.checkout_card .cards .card .properties .propertie:first-of-type {
  padding-top: 0;
}
.checkout_card .cards .card .properties .propertie:first-of-type button {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
}
.checkout_card .cards .card .properties .propertie:last-of-type {
  padding-bottom: 0;
}
.checkout_card .cards .card .properties .propertie:last-of-type button {
  text-transform: uppercase;
}
.checkout_card .cards .card .properties .propertie p {
  font-family: "Display Regular";
  font-size: 9px;
  color: #000;
  margin: 0;
  max-width: 100px;
}
.checkout_card .cards .card .properties .propertie button {
  font-family: "Display Regular";
  font-size: 11px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  width: 210px;
  color: #606060;
  background: inherit;
  border: 1px solid #ff8025;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  margin: 0;
  padding: 5px 7px;
}
.checkout_card .cards .card .properties .propertie button .arroy {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 18px;
}
.checkout_card .cards .card .properties .propertie button .arroy a {
  height: 10px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-decoration: none;
}
.checkout_card .cards .card .properties .propertie button a {
  font-size: 14px;
  color: #ff8025;
}
.checkout_card .cards .card .attention {
  font-family: "Display Regular";
  padding: 25px 13px 25px;
  width: 100%;
  color: #606060;
  font-size: 8.5px;
  margin: 0;
}
.checkout_card .cards .card .attention span {
  color: #ff8025;
}
.checkout_card .cards .card .attention i {
  font-size: 12px;
  padding-left: 10px;
}
.checkout_card .capabilities {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 25px 0 43px;
  width: 100%;
}
.checkout_card .capabilities .capabilitie {
  width: 50%;
  border: 1px solid #cccccb;
  padding: 25px 50px 20px 40px;
  font-family: "Display Medium";
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  font-size: 22px;
  color: #ff8025;
}
.checkout_card .capabilities .capabilitie:first-of-type {
  -webkit-border-radius: 5px 0 0 5px;
  border-radius: 5px 0 0 5px;
  border-right: 0;
}
.checkout_card .capabilities .capabilitie:last-of-type {
  -webkit-border-radius: 0 5px 5px 0;
  border-radius: 0 5px 5px 0;
}
.checkout_card .capabilities .capabilitie span {
  font-family: "Display Regular";
  font-size: 16px;
  color: #aeacac;
  display: block;
}
.checkout_card .capabilities .capabilitie i {
  color: #aeacac;
  margin-left: 5px;
}
.checkout_card .btnMaim_wide {
  margin: 0 auto;
  font-size: 19px;
}
.checkout_card .also {
  max-width: 310px;
  width: 100%;
  padding-top: 20px;
  margin: 0 auto;
}
.checkout_card .also p {
  text-align: center;
  font-family: "Display Regular";
  font-size: 14px;
  line-height: 22px;
  color: #949393;
}
.checkout_card .also a {
  text-decoration: none;
  color: #000;
  font-family: "Display Medium";
  font-size: 20px;
  width: 100%;
  text-align: center;
  margin: 0 auto;
  display: block;
}

.brand_selection {
  width: 70%;
  min-width: 980px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 30px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  margin: 0 auto;
}
.brand_selection .top {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  width: 100%;
  padding-bottom: 15px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.brand_selection .top .btn_radius {
  position: absolute;
  background: #ff8025;
  color: #fff;
  left: 0;
}
.brand_selection .top .btn_radius i {
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
.brand_selection .top .btn_radius:hover i {
  padding-right: 20px;
}
.brand_selection .top .myself .stages .stage:nth-child(1) {
  background: #ff8025; /*#d26d2c;*/
}
.brand_selection .brand {
  position: relative;
  width: 100%;
  margin-bottom: 45px;
  background: #fff;
  border: 1px solid #eaeaea;
  -webkit-border-radius: 0 0 5px 5px;
  border-radius: 0 0 5px 5px;
  -webkit-box-shadow: 3px 33px 32px -6px #ebebec;
  box-shadow: 3px 33px 32px -6px #ebebec;
}
.brand_selection .brand h2 {
  font-family: "Display Medium";
  font-size: 16px;
  color: #fff;
  background: #ff8025;
  width: 100%;
  text-align: center;
  padding: 12px 0;
  margin: 0;
  -webkit-border-radius: 5px 5px 0 0;
  border-radius: 5px 5px 0 0;
  font-weight: 500;
}
.brand_selection .brand .switch_BIG {
  position: absolute;
  z-index: 10;
  top: 143px;
}
.brand_selection .brand .switch_BIG.prev {
  left: 0;
}
.brand_selection .brand .switch_BIG.next {
  right: 0;
}
.brand_selection .brand .left_right {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
.brand_selection .brand .left_right .left {
  width: 67.2%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-right: 1px solid #eaeaea;
}
.brand_selection .brand .left_right .left .top {
  padding: 30px 30px 0 50px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  margin: 0;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  list-style: none;
}
.brand_selection .brand .left_right .left .top li {
  width: 100%;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 10px 0;
}
.brand_selection .brand .left_right .left .top li p {
  width: 85px;
  font-size: 10px;
  line-height: 14px;
  text-transform: uppercase;
  font-family: "Display Regular";
  color: #000;
  margin: 0;
}
.brand_selection .brand .left_right .left .top li .selectio {
  height: 40px;
  background: inherit;
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
  padding: 0 13px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  font-family: "Display Medium";
  font-size: 14px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  border: 1px solid #cecdcd;
  color: #acabaa;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
.brand_selection .brand .left_right .left .top li .selectio:hover,
.brand_selection .brand .left_right .left .top li .selectio.active {
  border: 1px solid #ff8025;
  color: #ff8025;
}
.brand_selection .brand .left_right .left .top li .selectio:hover i,
.brand_selection .brand .left_right .left .top li .selectio.active i {
  color: #ff8025;
}
.brand_selection .brand .left_right .left .top li .selectio:disabled {
  border: 1px solid #cecdcd;
  color: #acabaa;
}
.brand_selection .brand .left_right .left .top li .selectio:disabled:hover {
  cursor: unset;
}
.brand_selection .brand .left_right .left .top li .selectio i {
  font-size: 18px;
  color: #cecdcd;
}
.brand_selection .brand .left_right .left .top li button {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 40px;
  width: 195px;
  background: #f7f7f8;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  border: 1px solid #cdcccb;
  font-size: 14px;
  font-family: "Display Regular";
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  cursor: pointer;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: #606060;
}
.brand_selection .brand .left_right .left .top li button:hover {
  background: #ff8025;
  color: #fff;
}
.brand_selection .brand .left_right .left .top li button:disabled {
  background: #f7f7f8;
  color: #ff8025;
  border-color: #ff8025;
}
.brand_selection .brand .left_right .left .top .nona_cars {
  max-width: 475px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.brand_selection .brand .left_right .left .top .nona_cars .nona_car {
  margin: 13px 0;
}
.brand_selection .brand .left_right .left .top .icon {
  -webkit-box-pack: justify;
  -webkit-justify-content: left; /*space-between*/
  -ms-flex-pack: justify;
  justify-content: left; /*space-between*/
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0;
  padding: 7px 0 7px 75px;
}
.brand_selection .brand .left_right .left .top .icon a {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  width: 48px;
  cursor: pointer;
  padding: 2px;
  border-radius: 5px;
  border: 1px #ffffff solid;
}
.brand_selection .brand .left_right .left .top .icon a.active {
  border: 1px #ff8025 solid;
  padding: 2px;
  border-radius: 5px;
}
.brand_selection .brand .left_right .left .top .icon a img {
  display: block;
  width: 100%;
}
.brand_selection .brand .left_right .left .top .selectioText {
  padding: 18px 0;
}
.brand_selection .brand .left_right .left .top .selectioText:first-of-type {
  padding-top: 10px;
}
.brand_selection .brand .left_right .left .top .selectioText:last-of-type {
  padding-bottom: 5px;
}
.brand_selection .brand .left_right .left .top .width_hight {
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  padding-bottom: 40px;
  justify-content: space-between;
}
.brand_selection .brand .left_right .left .top .width_hight .sizes {
  display: flex;
  align-items: end;
}
.brand_selection .brand .left_right .left .top .width_hight p {
  padding-bottom: 4px;
  width: 75px;
}
.brand_selection .brand .left_right .left .top .width_hight .length_hight {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 200px;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
}
.brand_selection .brand .left_right .left .top .width_hight .length_hight span {
  font-family: "Display Regular";
  font-size: 9px;
  color: #a9a9a9;
}
.brand_selection
  .brand
  .left_right
  .left
  .top
  .width_hight
  .length_hight
  .selectio {
  width: 70px;
  font-family: "Display Regular";
  padding: 0 0 0 13px;
  cursor: auto;
}
.brand_selection .brand .left_right .left .top .width_hight .length_hight i {
  font-size: 18px;
  color: #a9a9a9;
  padding: 10px;
}
.brand_selection .brand .left_right .left .top .width_hight .text_square_main {
  width: 80px;
}
.brand_selection
  .brand
  .left_right
  .left
  .top
  .width_hight
  .text_square_main
  .square {
  position: relative;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
.brand_selection
  .brand
  .left_right
  .left
  .top
  .width_hight
  .text_square_main
  .square:hover
  .text_square {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
.brand_selection
  .brand
  .left_right
  .left
  .top
  .width_hight
  .text_square_main
  .square
  .text_square {
  position: absolute;
  bottom: 0px;
  padding-bottom: 50px;
  left: -105px;
  display: none;
}
.brand_selection .brand .left_right .left .top .width_hight .quantity {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  width: 137px;
}
.brand_selection
  .brand
  .left_right
  .left
  .top
  .width_hight
  .quantity
  .selectio {
  width: 80px;
  color: #606060;
}
.brand_selection
  .brand
  .left_right
  .left
  .top
  .width_hight
  .quantity
  .selectio:hover {
  color: #606060;
}
.brand_selection
  .brand
  .left_right
  .left
  .top
  .width_hight
  .quantity
  .selectio:hover
  a
  i {
  color: #cecdcd;
}
.brand_selection .brand .left_right .left .top .width_hight .quantity .arrow {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-bottom: 5px;
  margin-left: 5px;
}
.brand_selection .brand .left_right .left .top .width_hight .quantity .arrow a {
  height: 12px;
}
.brand_selection
  .brand
  .left_right
  .left
  .top
  .width_hight
  .quantity
  .arrow
  a
  i {
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
.brand_selection
  .brand
  .left_right
  .left
  .top
  .width_hight
  .quantity
  .arrow
  a
  i:hover {
  color: #ff8025;
}
.brand_selection .brand .left_right .left .bottom {
  padding: 10px 24px;
  border-top: 1px solid #eaeaea;
  width: 100%;
  height: 128px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
.brand_selection .brand .left_right .left .bottom ul {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
}
.brand_selection .brand .left_right .left .bottom ul li {
  width: 85px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 0 2.5px;
}
.brand_selection .brand .left_right .left .bottom ul li:hover .car,
.brand_selection .brand .left_right .left .bottom ul li.active .car {
  border: 1px solid #ff8025;
}
.brand_selection .brand .left_right .left .bottom ul li:hover .car p,
.brand_selection .brand .left_right .left .bottom ul li.active .car p {
  color: #ff8025;
}
.brand_selection .brand .left_right .left .bottom ul li:hover a i,
.brand_selection .brand .left_right .left .bottom ul li.active a i {
  opacity: 1;
}
.brand_selection .brand .left_right .left .bottom ul li:hover a i:hover,
.brand_selection .brand .left_right .left .bottom ul li.active a i:hover {
  color: #606060;
}
.brand_selection .brand .left_right .left .bottom ul li .car {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-decoration: none;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  height: 85px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  padding: 0 15px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  border: 1px solid #fff;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
.brand_selection .brand .left_right .left .bottom ul li .car p {
  font-family: "Display Medium";
  font-size: 12px;
  color: #606060;
  margin: 0;
  width: 100%;
  text-align: center;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
.brand_selection .brand .left_right .left .bottom ul li .car img {
  display: block;
  width: 100%;
}
.brand_selection .brand .left_right .left .bottom ul li a i {
  color: #d3d2d0;
  opacity: 0;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  padding-top: 7px;
}
.brand_selection .brand .left_right .left .bottom button {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  width: 40px;
  height: 40px;
  font-size: 20px;
  cursor: pointer;
  margin: 15px 0 0 20px;
  font-family: "Display Regular";
  border: none;
  background: #ff8025;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  color: #fff;
}
.brand_selection .brand .left_right .right {
  width: 50%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.brand_selection .brand .left_right .right .top {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: calc(100% - 128px);
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}
.brand_selection .brand .left_right .right .top p {
  font-size: 14px;
  font-family: "Display Medium";
  color: #606060;
  width: 100%;
  text-align: center;
  text-transform: uppercase;
}
.brand_selection .brand .left_right .right .top .img {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding-right: 30px;
}
.brand_selection .brand .left_right .right .top .img img {
  display: block;
  width: 100%;
}
.brand_selection .brand .left_right .right .bottom {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  padding-top: 0;
  border-top: 1px solid #eaeaea;
  height: 128px;
  justify-content: center;
}
.brand_selection .brand .left_right .right .bottom p {
  max-width: 300px;
  padding: 0 30px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin: 5px 0;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-transform: uppercase;
  font-family: "Display Medium";
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  font-size: 14px;
}
.brand_selection .brand .left_right .right .bottom p:first-of-type {
  color: #606060;
}
.brand_selection .brand .left_right .right .bottom p:last-of-type {
  color: #ff8025;
}
.brand_selection .brand .left_right .right .bottom p span {
  font-size: 20px;
}
.brand_selection .btnMaim_wide {
  margin: auto;
  font-size: 19px;
}
.brand_selection .also {
  max-width: 310px;
  width: 100%;
  padding-top: 20px;
  margin: 0 auto;
}
.brand_selection .also p {
  text-align: center;
  font-family: "Display Regular";
  font-size: 14px;
  line-height: 22px;
  color: #949393;
}
.brand_selection .also a {
  text-decoration: none;
  color: #000;
  font-family: "Display Medium";
  font-size: 20px;
  width: 100%;
  text-align: center;
  margin: 0 auto;
  display: block;
}

.constructor_choice {
  width: 980px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 30px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  margin: 0 auto;
}
.constructor_choice .top {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  width: 100%;
  padding-bottom: 35px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.constructor_choice .top .btn_radius {
  position: absolute;
  background: #ff8025;
  color: #fff;
  left: 0;
}
.constructor_choice .top .btn_radius i {
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
.constructor_choice .top .btn_radius:hover i {
  padding-right: 20px;
}
.constructor_choice .top .myself .stages .stage:nth-child(1) {
  background: #e2ac33;
}
.constructor_choice .top .myself .stages .stage:nth-child(2) {
  background: #d26d2c;
}
.constructor_choice .cards {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 100%;
}
.constructor_choice .cards .card {
  margin: 0 8px;
  background: #fff;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px solid #eaeaea;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  height: 310px;
  padding: 35px 50px 30px 30px;
  width: 420px;
  -webkit-box-shadow: 3px 33px 32px -6px #ebebec;
  box-shadow: 3px 33px 32px -6px #ebebec;
  transition: transform 200ms;
}
.constructor_choice .cards .card:hover {
  transform: scale(1.05);
}
.constructor_choice .cards .card:first-of-type {
  width: 264px;
  padding: 35px 18px 30px;
  margin-left: 0;
}
.constructor_choice .cards .card:last-of-type {
  width: 264px;
  padding: 35px 18px 30px;
  margin-right: 0;
}
.constructor_choice .cards .card:last-of-type h2 {
  padding: 0 25px;
}
.constructor_choice .cards .card h2 {
  font-size: 16px;
  line-height: 26px;
  font-family: "Display Medium";
  color: #000;
  font-weight: 100;
  text-transform: uppercase;
  width: 100%;
  text-align: center;
  margin: 0;
}
.constructor_choice .cards .card p {
  margin: 0;
  width: 100%;
  font-family: "Display Regular";
  font-size: 12px;
  line-height: 20px;
  color: #606060;
  text-align: center;
}
.constructor_choice .cards .card img {
  display: block;
  width: 100%;
}
.constructor_choice .cards .card .btn.btnMaim_wide {
  width: 295px;
  height: 40px;
}
.constructor_choice .cards .card .btn.btnMaim_wide i {
  font-size: 20px;
}
.constructor_choice .also {
  max-width: 310px;
  width: 100%;
  padding-top: 60px;
  margin: 0 auto;
}
.constructor_choice .also p {
  text-align: center;
  font-family: "Display Regular";
  font-size: 14px;
  line-height: 22px;
  color: #949393;
}
.constructor_choice .also a {
  text-decoration: none;
  color: #000;
  font-family: "Display Medium";
  font-size: 20px;
  width: 100%;
  text-align: center;
  margin: 0 auto;
  display: block;
}
.btn.btnLink {
  text-transform: uppercase;
  font-size: 14px;
  line-height: 15px;
  color: #606060;
  margin-top: 20px;
  width: 250px;
  margin-left: auto;
  margin-right: auto;
}
.btn.btnLink:hover {
  text-decoration: underline;
}
.btn.btnLink svg {
  fill: #606060;
  margin-left: 10px;
}
.btn.disabled {
  cursor: not-allowed;
}

@media screen and (max-height: 800px) {
  .brand_selection .brand .left_right .left .top {
    width: calc(100% - 138px);
    padding-left: 0;
  }
  .brand_selection .brand .left_right .left {
    flex-direction: row-reverse;
    padding-left: 20px;
  }
  .brand_selection .brand .left_right .left .bottom {
    flex-direction: column;
    align-items: center;
  }
  .brand_selection .brand .left_right .left .bottom {
    height: 100%;
  }
  .brand_selection .brand .left_right .left .bottom button {
    margin: 10px 0 0;
  }
  .brand_selection .brand .left_right .left .bottom ul {
    flex-direction: column;
  }
  .checkout .btn.nonaMain {
    margin-top: 20px;
  }
}
