body {
  overflow-x: auto;
}

@media screen and (max-height: 700px) {
  body {
    overflow-y: auto;
  }
}

.pointer {
  cursor: pointer;
}

.dn {
  display: none;
}

.h-hidden {
  display: none;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  display: none;
  -webkit-appearance: none;
  margin: 0;
}
.wrap-selectio {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
/*Ð´Ð»Ñ Ð±Ð»Ð¾ÐºÐ° Ð¿Ð»ÐµÐ½Ð¾Ðº*/
.table_nona.active_choice table tbody {
  border: 1px solid #ff8025;
}
.checkout_card
  .cards
  .card
  .table_nona.table_card-3.active_choice
  table
  tbody
  tr
  td {
  border: 1px solid #ff8025;
  color: #000;
}
.checkout_card
  .cards
  .card
  .table_nona.table_card-3.active_choice
  table
  tbody
  tr
  td:first-of-type {
  border-right: 0;
  text-transform: uppercase;
}
.checkout_card
  .cards
  .card
  .table_nona.table_card-3.active_choice
  table
  tbody
  tr
  td:last-of-type {
  border-left: 0;
}
.checkout_card
  .cards
  .card
  .table_nona.table_card-3.active_choice
  table
  tbody
  tr
  td
  a {
  color: #000;
}
.checkout_card .cards .card .table_nona.table_card-3 table tbody tr td a {
  color: #acabaa;
}
.checkout_card .cards .card .properties .propertie select {
  font-family: "Display Regular";
  font-size: 11px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  width: 210px;
  color: #606060;
  background: inherit;
  border: 1px solid #ff8025;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  margin: 0;
  padding: 5px 7px;
}
.checkout_card .cards .card .table_nona span.active_choice {
  color: #606060;
}
.checkout_card .cards .card .inputCheck.deactive_choice label {
  color: #acabaa;
}
.checkout_card .cards .card .properties.deactive_choice .propertie p {
  color: #acabaa;
}
.checkout_card .cards .card .properties.deactive_choice .propertie select {
  border: 1px solid #d4d4d2;
  color: #acabaa;
}
.checkout_card .cards .card .properties.deactive_choice .propertie button {
  border: 1px solid #d4d4d2;
  color: #acabaa;
}
.checkout_card .cards .card .properties.deactive_choice .propertie button a {
  color: #acabaa;
}
.checkout_card .cards .card .attention.deactive_choice {
  color: #acabaa;
}
.checkout_card .cards .card .attention.deactive_choice span {
  color: #acabaa;
}
/*Ð²Ñ‹Ð¿Ð°Ð´Ð°ÑŽÑ‰Ð¸Ðµ ÑÐ¿Ð¸ÑÐºÐ¸*/
.dropdown {
  cursor: pointer;
}
.dropdown-content {
  position: absolute;
  max-width: 290px;
  width: 100%;
  background-color: #f9f9f9;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  z-index: 1;
}
.checkout_card
  .cards
  .card
  .table_nona.table_card-3.active_choice
  table.dropdown:hover
  tbody
  tr
  td
  a.dropbtn {
  color: #ff8025;
}
/*table current car 1*/
.checkout_card .cards .card .current_cars table tbody tr td:nth-child(1) {
  width: 50%;
}
.checkout_card .cards .card .current_cars table tbody tr td:nth-child(2) {
  width: 25%;
}
.checkout_card .cards .card .current_cars table tbody tr td:nth-child(3) {
  width: 13%;
}
.checkout_card .cards .card .current_cars table tbody tr td:nth-child(4) {
  width: 12%;
}
/*table current car 2*/
.checkout_card
  .cards
  .card
  .current_cars
  table.dropdown-content-current-car
  tbody
  tr
  td:nth-child(1) {
  width: 50%;
}
.checkout_card
  .cards
  .card
  .current_cars
  table.dropdown-content-current-car
  tbody
  tr
  td:nth-child(2) {
  width: 25%;
}
.checkout_card
  .cards
  .card
  .current_cars
  table.dropdown-content-current-car
  tbody
  tr
  td:nth-child(3) {
  width: 13%;
}
.checkout_card
  .cards
  .card
  .current_cars
  table.dropdown-content-current-car
  tbody
  tr
  td:nth-child(4) {
  width: 12%;
}
/**/
.dropdown-content-current-car {
  position: absolute;
  top: 100%;
  margin-top: 3px;
  max-width: 290px;
  width: 100%;
  background-color: #f9f9f9;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  z-index: 1;
}
.table_nona.color-1.current_cars tr {
  cursor: pointer;
}
.table_nona table tbody tr.active {
  background: #ff802540;
}
.dropdown-content table tr {
  cursor: pointer;
}
.scrolling_items .item {
  cursor: pointer;
}
.scrolling_items .item.active {
  color: #ff8025;
}
.list_points {
  cursor: pointer;
}
.list-point__switcher {
  margin-right: 10px;
}

.element-constructor {
  cursor: pointer;
}
.in_list li {
  cursor: pointer;
}
/*Ð²Ñ‹Ð¿Ð°Ð´Ð°ÑŽÑ‰Ð¸Ðµ Ð±Ð»Ð¾ÐºÐ¸ Ð´Ð»Ñ Ð¿Ñ€Ð°Ð²Ð¾Ð³Ð¾ Ð¼ÐµÐ½ÑŽ Ð² ÐºÐ¾Ð½ÑÑ‚Ñ€ÑƒÑ‚Ð¾Ñ€Ðµ*/
.menuMain .top_part .list_points li {
  position: relative;
}

.dropleft-content-construct-menu {
  position: absolute;
  width: 100%;
  z-index: 1;
  right: 100%;
  margin-right: -40px;
  top: -60px;
}
.dropleft-holder {
  background-color: #f9f9f9;
  box-shadow: 0 8px 16px 0 rgb(0 0 0 / 20%);
  padding: 1px;
  margin-right: 40px;
}
.dropleft-header {
  font-family: "Display Regular";
  text-transform: uppercase;
  color: #161616;
  font-size: 11px;
  margin: 15px 15px;
  display: flex;
}

.dropleft-subheader {
  margin: 10px 15px;
  font-size: 11px;
  font-family: "Display Regular";
}

.dropleft-header .solid {
  color: #9A9A9A;
  border-right: 1px solid #9A9A9A;
  margin-right: 5px;
  transition: .3s all;
}

.dropleft-header__text {
  color: #9A9A9A;
  padding: 2px 5px;
}

.dropleft-header__text:hover {
  color: #161616;
}

.dropleft-header__text.active {
  color: #161616;
  border: 1px solid #ff8025;
  border-radius: 5px;
}

.dropleft-content-construct-menu hr {
  border: none;
  color: #efefef;
  background-color: #efefef;
  height: 1px;
}
.dropleft-holder:before {
  content: "";
  position: absolute;
  z-index: 5;
  left: 100%;
  width: 17px;
  height: 25px;
  background: #f9f9f9;
  transform: translate(-15px, 100px);
  -webkit-clip-path: polygon(100% 50%, 0 100%, 0 0);
  clip-path: polygon(100% 50%, 0 100%, 0 0);
  margin-left: -28px;
  top: -15px;
}
.dropleft-items {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding: 3px;
}

.dropleft-items.stickers {
  padding: 15px;
}

.dropleft-items.stickers .dropleft-item {
  padding: 10px;
  margin: 0;
  border: none;
}

.dropleft-items.template-items-scroll {
  margin-left:15px;
  overflow: scroll;
  max-height: 100px;
}

.dropleft-items.template-items-scroll::-webkit-scrollbar-thumb {
  background: #FF8A00;
  border-radius: 5px;
}

.dropleft-items.template-items-scroll::-webkit-scrollbar {
  width: 4px;
}

.template-name {
  font-family: "Display Regular";
  font-size: 12px;
  color: #797979;
  text-align: center;
  margin: 5px 0 0 0;
}

.dropleft-content-construct-menu .dropleft-holder .dropleft-items .dropleft-subheader {
  font-family: 'Display Regular';
  text-transform: uppercase;
  color: #606161;
  font-size: 11px;
  margin: 15px 10px;
}

.select-container {
  position: relative;
}

.select-container .arrow-down{
  position: absolute;
  right: 40px;
  top: 11px;
  transition: .3s all;
}

.dropleft-content-construct-menu .dropleft-holder .selectio {
  margin: 0 15px;
  padding: 6px;
  width: 210px;
  color: #ff8025;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #ff8025;
  border-radius: 4px;
  appearance: none;
  cursor: pointer;
}
.dropleft-content-construct-menu .dropleft-holder .selectio:after{
  content: '^';
  display: block;
  color: #ff8025;
}
.dropleft-content-construct-menu .dropleft-holder .slidecontainer input {
  position: relative;
  margin: 0;
  -webkit-appearance: none;
  background: #C4C4C4;
  background-image: -webkit-gradient(linear, 20% 0%, 20% 100%, color-stop(0%, #FF8A00), color-stop(100%, #FF8A00));
  background-image: -webkit-linear-gradient(left, #FF8A00 0%,#FF8A00 100%);
  background-image: -moz-linear-gradient(left, #FF8A00 0%, #FF8A00 100%);
  background-image: -o-linear-gradient(to right, #FF8A00 0%,#FF8A00 100%);
  background-image: linear-gradient(to right, #FF8A00 0%,#FF8A00 100%);
  background-repeat: no-repeat;
}

.dropleft-content-construct-menu .dropleft-holder .slidecontainer input::-webkit-slider-runnable-track {
  cursor: pointer;
  box-shadow: none;
  background: transparent;
  width: 100%;
}


.dropleft-content-construct-menu .dropleft-holder .slidecontainer input:after{
  position: absolute;
  background-color: #ff8025;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  left: -2.5px;
  top: 2.5px;
}
.dropleft-content-construct-menu .dropleft-holder .slidecontainer input:before{
  position: absolute;
  background-color: #ff8025;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  right: -7.5px;
  top: 7.5px;
}
.checkout .forms .form .fill_in .right .upload .btn input {
  width: 0;
}

.dropleft-bg .dropleft-item,.dropleft-item.pipette {
  padding: unset;
  margin: unset;
}

.dropleft-item {
  padding: 2px;
  border: 1px solid transparent;
  margin: 2px;
}

.dropleft-item.template {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 6px;
}

.dropleft-item.template img{
  width: 45px;
  height: 45px;
  padding: 6px;
}

.dropleft-item.template img.active,.dropleft-item.template:hover img {
  border: 1px solid #ff8025;
  border-radius: 5px;
}

.dropleft-item img {
  display: block;
  width: 36px;
  height: 36px;
  border-radius: 5px;
}
.dropleft-item:not(.template):hover,
.dropleft-item:not(.template).active {
  border: 1px solid #ff8025;
  /*border-radius: 5px;*/
}
.dropleft-items__color {
  cursor: auto;
  justify-content: start;
  padding: 0;
  margin: 5px -7px 20px -5px;
}
.dropleft-items__color .dropleft-bg,
.dropleft-items__color .dropleft-item {
  width: 24px;
  height: 24px;
  border-radius: 50%;
}
.dropleft-items__color .dropleft-bg {
  margin: 4px;
  background: url("~@/components/vue-color-gradient-picker/assets/images/alpha-background.svg");
}
.dropleft-items__color .dropleft-item {
  border: 1px solid #e3e3e3;
}
.dropleft-items__color .dropleft-item.enable {
  cursor: pointer;
}
.dropleft-items__color .dropleft-item.enable:hover,
.dropleft-items__color .dropleft-item.enable.active {
  border-radius: 50%;
  border: 2px solid white;
  padding: 10px;
  box-shadow: 0 0 0 2px #ff8025;
}

.color-gam {
  margin-left: 10px;
  padding-bottom: 15px;
}

.color-gam .dropleft-item img {
  display: block;
  width: 68px;
  height: 32px;
}
.slidecontainer {
  margin-left: 15px;
  margin-right: 15px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.start-thumb,.end-thumb {
  border-radius: 50%;
  transition: .3s all;
  cursor: pointer;
}

.start-thumb,.end-thumb:hover {
  background: #FF8A00;
}

.start-thumb {
  width: 9px;
  height: 9px;
  background: #FF8A00;
}

.end-thumb {
  width: 19px;
  height: 19px;
  background: #C4C4C4;
}

.slider {
  -webkit-appearance: none;
  appearance: none;
  width: 200px;
  height: 2px;
  background: #ff8025;
  outline: none;
  opacity: 0.7;
  margin:0;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}
.slider:hover {
  opacity: 1;
}
.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 14px;
  height: 14px;
  border-radius: 10px;
  background: #ff8025;
  cursor: pointer;
}

/*ÑÑ‚Ñ€ÐµÐ»ÐºÐ¸ Ð´Ð»Ñ ÐºÐ¾Ð½ÑÑ‚Ñ€ÑƒÐºÑ‚Ð¾Ñ€Ð°*/
#parent-arrow-position {
  position: relative;
}
.design_choice .choice img.arrow-position {
  width: 21px;
  position: absolute;
  cursor: pointer;
  opacity: 0;
}
.design_choice .choice img.arrow-position:hover {
  opacity: 1;
}
#arrow-position-back {
  left: 127px;
  bottom: 11px;
}
#arrow-position-top {
  left: 75px;
  bottom: 35px;
}
#arrow-position-front {
  top: 35px;
  left: 26px;
}
#arrow-position-left {
  left: 123px;
  top: 36px;
}
#arrow-position-right {
  left: 26px;
  bottom: 10px;
}
/*ÐºÐ¾Ñ€Ñ€ÐµÐºÑ‚Ð¸Ñ€Ð¾Ð²ÐºÐ¸ Ð¸Ð½Ñ‚ÐµÑ€Ñ„ÐµÐ¹ÑÐ°*/
.menuMain .top_part .header .btns_bottom .btn_bottom:first-child {
  margin-right: 10px;
}
.left-menu-ico {
  fill: #cedad6;
}
.menuMain .top_part .list_points li a:hover .left-menu-ico {
  fill: #ff8025;
}
/*Ð²Ñ‹Ð±Ð¾Ñ€ Ð¾ÐºÑ€Ð°ÑÐºÐ¸*/
.area-pasting-block {
  padding: 20px;
  border-bottom: 1px solid #e3e3e3;
}
.area-pasting-title {
  color: #738183;
  margin-bottom: 20px;
  text-transform: uppercase;
  font-size: 11px;
}
table.area-pasting-cars {
  margin: auto;
  border-collapse: collapse;
  cursor: pointer;
}
table.area-pasting-cars td {
  padding: 15px 20px 15px 20px;
  border: 1px solid #f9f9f9;
  width: 109px;
}
table.area-pasting-cars td .car-part {
  fill: #cedad6;
  stroke: #cedad6;
}
table.area-pasting-cars td:hover:not(.disable) .car-part,
table.area-pasting-cars td.active .car-part {
  fill: #ff8025;
  stroke: #ff8025;
}

/* v-tooltip */
.tooltip {
  display: block !important;
  z-index: 10000;
  width: 270px;
}

.tooltip .tooltip-inner {
  font-family: "Display Regular";
  font-size: 12px;
  line-height: 18px;
  background: lightgrey;
  color: black;
  border-radius: 6px;
  padding: 5px 10px 4px;
}

.tooltip .tooltip-arrow {
  width: 0;
  height: 0;
  border-style: solid;
  position: absolute;
  margin: 5px;
  border-color: lightgrey;
  z-index: 1;
}

.tooltip[x-placement^="top"] {
  margin-bottom: 5px;
}

.tooltip[x-placement^="top"] .tooltip-arrow {
  border-width: 5px 5px 0 5px;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  bottom: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}

.tooltip[x-placement^="bottom"] {
  margin-top: 5px;
}

.tooltip[x-placement^="bottom"] .tooltip-arrow {
  border-width: 0 5px 5px 5px;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-top-color: transparent !important;
  top: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}

.tooltip[x-placement^="right"] {
  margin-left: 5px;
}

.tooltip[x-placement^="right"] .tooltip-arrow {
  border-width: 5px 5px 5px 0;
  border-left-color: transparent !important;
  border-top-color: transparent !important;
  border-bottom-color: transparent !important;
  left: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
}

.tooltip[x-placement^="left"] {
  margin-right: 5px;
}

.tooltip[x-placement^="left"] .tooltip-arrow {
  border-width: 5px 0 5px 5px;
  border-top-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  right: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
}

.tooltip.popover .popover-inner {
  background: #f9f9f9;
  color: black;
  padding: 24px;
  border-radius: 5px;
  box-shadow: 0 5px 30px rgba(black, 0.1);
}

.tooltip.popover .popover-arrow {
  border-color: #f9f9f9;
}

.tooltip[aria-hidden="true"] {
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.15s, visibility 0.15s;
}

.tooltip[aria-hidden="false"] {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.15s;
}

#views {
  display: flex;
  justify-content: center;
}
